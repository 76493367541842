import React from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { useOrganization } from './OrganizationProvider';

const PublicApolloProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
    const client = React.useRef<ApolloClient<NormalizedCacheObject> | undefined>();

    const { gql } = useOrganization();

    const resolvedGraphQLUri = gql;

    if (resolvedGraphQLUri) {
        console.log('Creating Apollo Client with resolved GraphQL URI:', resolvedGraphQLUri);
    }

    const httpLink = new HttpLink({
        uri: gql,
    });

    const authLink = setContext(async (_, { headers, ...rest }) => {
        return { headers, ...rest };
    });

    client.current = new ApolloClient({
        connectToDevTools: true,
        link: authLink.concat(httpLink),
        cache: new InMemoryCache({}),
    });
    return <ApolloProvider client={client.current}>{children}</ApolloProvider>;
};

export default PublicApolloProvider;
